/**
Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ 
**/

/** Ionic CSS Variables **/
:root {
  .pro-new-design {
    /** Primary / Cosmic Purple **/
    --ion-color-primary: #5f41ed;
    --ion-color-primary-rgb: 95, 65, 237;
    --ion-color-primary-contrast: #fff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #5439d1;
    --ion-color-primary-tint: #6f54ef;

    /** Secondary / Aquamarine **/
    --ion-color-secondary: #57d3d3;
    --ion-color-secondary-rgb: 87, 211, 211;
    --ion-color-secondary-contrast: #000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #4dbaba;
    --ion-color-secondary-tint: #68d7d7;

    /** Tertiary / Steel Gray **/
    --ion-color-tertiary: #a8a8a8;
    --ion-color-tertiary-rgb: 168, 168, 168;
    --ion-color-tertiary-contrast: #000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #949494;
    --ion-color-tertiary-tint: #b1b1b1;

    /** Success / Meadow **/
    --ion-color-success: #12b682;
    --ion-color-success-rgb: 18, 182, 130;
    --ion-color-success-contrast: #000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #10a072;
    --ion-color-success-tint: #2abd8f;

    /** Warning / Goldenrod **/
    --ion-color-warning: #facc15;
    --ion-color-warning-rgb: 250, 204, 21;
    --ion-color-warning-contrast: #000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #dcb412;
    --ion-color-warning-tint: #fbd12c;

    /** Error / Danger / Coral Red **/
    --ion-color-danger: #f75555;
    --ion-color-danger-rgb: 247, 85, 85;
    --ion-color-danger-contrast: #000;
    --ion-color-danger-contrast-rgb: 0, 0, 0;
    --ion-color-danger-shade: #d94b4b;
    --ion-color-danger-tint: #f86666;

    /** Light / Silk Lavender **/
    --ion-color-light: #e3e2f2;
    --ion-color-light-rgb: 227, 226, 242;
    --ion-color-light-contrast: #000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #c8c7d5;
    --ion-color-light-tint: #e6e5f3;

    /** Medium / Silver Sky **/
    --ion-color-medium: #c3c8dd;
    --ion-color-medium-rgb: 195, 200, 221;
    --ion-color-medium-contrast: #000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #acb0c2;
    --ion-color-medium-tint: #c9cee0;

    /** Dark / Stormy Sky **/
    --ion-color-dark: #696c7b;
    --ion-color-dark-rgb: 105, 108, 123;
    --ion-color-dark-contrast: #fff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #5c5f6c;
    --ion-color-dark-tint: #787b88;

    /** Ionic Stepped Colors 
        Text Color **/
    --ion-text-color: var(--pro-color-black);
    --ion-text-color-rgb: var(--pro-color-black-rgb);
    --ion-text-color-step-50: #2c3441;
    --ion-text-color-step-100: #373f4b;
    --ion-text-color-step-150: #424955;
    --ion-text-color-step-200: #4c535f;
    --ion-text-color-step-250: #575e69;
    --ion-text-color-step-300: #626873;
    --ion-text-color-step-350: #6d727d;
    --ion-text-color-step-400: #787c87;
    --ion-text-color-step-450: #838791;
    --ion-text-color-step-500: #8e919b;
    --ion-text-color-step-550: #989ba5;
    --ion-text-color-step-600: #a3a6af;
    --ion-text-color-step-650: #aeb0b9;
    --ion-text-color-step-700: #b9bac3;
    --ion-text-color-step-750: #c4c5cd;
    --ion-text-color-step-800: #cfcfd7;
    --ion-text-color-step-850: #d9d9e1;
    --ion-text-color-step-900: #e4e3eb;
    --ion-text-color-step-950: #efeef5;

    /** Background Color **/
    --ion-background-color: var(--pro-color-lilac-whisper);
    --ion-background-color-rgb: var(--pro-color-lilac-whisper-rgb);
    --ion-background-color-step-50: #efeef5;
    --ion-background-color-step-100: #e4e3eb;
    --ion-background-color-step-150: #d9d9e1;
    --ion-background-color-step-200: #cfcfd7;
    --ion-background-color-step-250: #c4c5cd;
    --ion-background-color-step-300: #b9bac3;
    --ion-background-color-step-350: #aeb0b9;
    --ion-background-color-step-400: #a3a6af;
    --ion-background-color-step-450: #989ba5;
    --ion-background-color-step-500: #8e919b;
    --ion-background-color-step-550: #838791;
    --ion-background-color-step-600: #787c87;
    --ion-background-color-step-650: #6d727d;
    --ion-background-color-step-700: #626873;
    --ion-background-color-step-750: #575e69;
    --ion-background-color-step-800: #4c535f;
    --ion-background-color-step-850: #424955;
    --ion-background-color-step-900: #373f4b;
    --ion-background-color-step-950: #2c3441;

    /**  Color **/
    --ion-color-step-50: #674aee;
    --ion-color-step-100: #6f53ef;
    --ion-color-step-150: #765cf0;
    --ion-color-step-200: #7e66f1;
    --ion-color-step-250: #866ff2;
    --ion-color-step-300: #8e78f2;
    --ion-color-step-350: #9581f3;
    --ion-color-step-400: #9d8af4;
    --ion-color-step-450: #a593f5;
    --ion-color-step-500: #ad9df6;
    --ion-color-step-550: #b4a6f7;
    --ion-color-step-600: #bcaff8;
    --ion-color-step-650: #c4b8f9;
    --ion-color-step-700: #ccc1fa;
    --ion-color-step-750: #d3cafb;
    --ion-color-step-800: #dbd3fb;
    --ion-color-step-850: #e3ddfc;
    --ion-color-step-900: #ebe6fd;
    --ion-color-step-950: #f2effe;

    /** Custom Colors 
        Primary Tint 400 **/
    --pro-color-primary-tint-400: #876dff;
    --pro-color-primary-tint-400-rgb: 135, 109, 255;
    --pro-color-primary-tint-400-contrast: #000;
    --pro-color-primary-tint-400-contrast-rgb: 0, 0, 0;
    --pro-color-primary-tint-400-shade: #7760e0;
    --pro-color-primary-tint-400-tint: #937cff;

    /** Primary Tint 300 **/
    --pro-color-primary-tint-300: #a592ff;
    --pro-color-primary-tint-300-rgb: 165, 146, 255;
    --pro-color-primary-tint-300-contrast: #000;
    --pro-color-primary-tint-300-contrast-rgb: 0, 0, 0;
    --pro-color-primary-tint-300-shade: #9180e0;
    --pro-color-primary-tint-300-tint: #ae9dff;

    /** Primary Tint 200 **/
    --pro-color-primary-tint-200: #c3b6ff;
    --pro-color-primary-tint-200-rgb: 195, 182, 255;
    --pro-color-primary-tint-200-contrast: #000;
    --pro-color-primary-tint-200-contrast-rgb: 0, 0, 0;
    --pro-color-primary-tint-200-shade: #aca0e0;
    --pro-color-primary-tint-200-tint: #c9bdff;

    /** Primary Tint 100 **/
    --pro-color-primary-tint-100: #f0edff;
    --pro-color-primary-tint-100-rgb: 240, 237, 255;
    --pro-color-primary-tint-100-contrast: #000;
    --pro-color-primary-tint-100-contrast-rgb: 0, 0, 0;
    --pro-color-primary-tint-100-shade: #d3d1e0;
    --pro-color-primary-tint-100-tint: #f2efff;

    /** Secondary Tint 600 **/
    --ion-color-secondary-tint-600: #48b0b0;
    --ion-color-secondary-tint-600-rgb: 72, 176, 176;
    --ion-color-secondary-tint-600-contrast: #000;
    --ion-color-secondary-tint-600-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-tint-600-shade: #3f9b9b;
    --ion-color-secondary-tint-600-tint: #5ab8b8;

    /** Secondary Tint 400 **/
    --pro-color-secondary-tint-400: #7ef4f4;
    --pro-color-secondary-tint-400-rgb: 126, 244, 244;
    --pro-color-secondary-tint-400-contrast: #000;
    --pro-color-secondary-tint-400-contrast-rgb: 0, 0, 0;
    --pro-color-secondary-tint-400-shade: #6fd7d7;
    --pro-color-secondary-tint-400-tint: #8bf5f5;

    /** Secondary Tint 300 **/
    --pro-color-secondary-tint-300: #9ef6f6;
    --pro-color-secondary-tint-300-rgb: 158, 246, 246;
    --pro-color-secondary-tint-300-contrast: #000;
    --pro-color-secondary-tint-300-contrast-rgb: 0, 0, 0;
    --pro-color-secondary-tint-300-shade: #8bd8d8;
    --pro-color-secondary-tint-300-tint: #a8f7f7;

    /** Secondary Tint 200 **/
    --pro-color-secondary-tint-200: #bff9f9;
    --pro-color-secondary-tint-200-rgb: 191, 249, 249;
    --pro-color-secondary-tint-200-contrast: #000;
    --pro-color-secondary-tint-200-contrast-rgb: 0, 0, 0;
    --pro-color-secondary-tint-200-shade: #a8dbdb;
    --pro-color-secondary-tint-200-tint: #c5fafa;

    /** Secondary Tint 100 **/
    --pro-color-secondary-tint-100: #d8fcfc;
    --pro-color-secondary-tint-100-rgb: 216, 252, 252;
    --pro-color-secondary-tint-100-contrast: #000;
    --pro-color-secondary-tint-100-contrast-rgb: 0, 0, 0;
    --pro-color-secondary-tint-100-shade: #bedede;
    --pro-color-secondary-tint-100-tint: #dcfcfc;

    /** Black **/
    --pro-color-black: #212a37;
    --pro-color-black-rgb: 33, 42, 55;
    --pro-color-black-contrast: #fff;
    --pro-color-black-contrast-rgb: 255, 255, 255;
    --pro-color-black-shade: #1d2530;
    --pro-color-black-tint: #373f4b;

    /** White **/
    --pro-color-white: #fff;
    --pro-color-white-rgb: 255, 255, 255;
    --pro-color-white-contrast: #000;
    --pro-color-white-contrast-rgb: 0, 0, 0;
    --pro-color-white-shade: #e0e0e0;
    --pro-color-white-tint: #fff;

    /** Lilac Whisper **/
    --pro-color-lilac-whisper: #faf8ff;
    --pro-color-lilac-whisper-rgb: 250, 248, 255;
    --pro-color-lilac-whisper-contrast: #000;
    --pro-color-lilac-whisper-contrast-rgb: 0, 0, 0;
    --pro-color-lilac-whisper-shade: #dcdae0;
    --pro-color-lilac-whisper-tint: #fbf9ff;

    /** Pearl White **/
    --pro-color-pearl-white: #f5f5f5;
    --pro-color-pearl-white-rgb: 245, 245, 245;
    --pro-color-pearl-white-contrast: #000;
    --pro-color-pearl-white-contrast-rgb: 0, 0, 0;
    --pro-color-pearl-white-shade: #d8d8d8;
    --pro-color-pearl-white-tint: #f6f6f6;

    /** Pink **/
    --pro-color-pink: #ea1e61;
    --pro-color-pink-rgb: 234, 30, 97;
    --pro-color-pink-contrast: #000;
    --pro-color-pink-contrast-rgb: 0, 0, 0;
    --pro-color-pink-shade: #ce1a55;
    --pro-color-pink-tint: #ec3571;

    /** Ionic Application Colors **/
    --ion-item-background: var(--pro-color-white);
    --ion-item-color: var(--ion-text-color);
    --ion-border-color: rgb(162 163 194 / 30%);
    --ion-box-shadow-color: rgb(0 0 0 / 10%);
    --ion-backdrop-color: var(--pro-color-black);

    /** ionic application vars **/
    --ion-font-family: 'Poppins';
    --ion-margin: 24px;
    --ion-padding: 24px;
    --ion-backdrop-opacity: 0.5;
    --pro-nav-bar-height: 80px;

    /** custom app vars **/
    --pro-content-padding: 24px;
    --pro-default-border: solid 0.55px var(--ion-border-color);
    --pro-blurry-footer-background: rgb(244 248 255 / 40%);

    /** gradients **/
    --pro-gradient-cosmic-purple: linear-gradient(286.17deg, #5f41ed 5.17%, #876dff 100%);
    --pro-gradient-aquamarine: linear-gradient(286.86deg, #48b0b0 -31.69%, #7cdfdf 109.91%);

    /** box-shadows **/
  }
}
/* stylelint-disable no-descending-specificity */
.pro-new-design {
  /** expose custom colors */
  .ion-color-black {
    --ion-color-base: var(--pro-color-black);
    --ion-color-base-rgb: var(--pro-color-black-rgb);
    --ion-color-contrast: var(--pro-color-black-contrast);
    --ion-color-contrast-rgb: var(--pro-color-black-contrast-rgb);
    --ion-color-shade: var(--pro-color-black-shade);
    --ion-color-tint: var(--pro-color-black-tint);
  }

  .ion-color-white {
    --ion-color-base: var(--pro-color-white);
    --ion-color-base-rgb: var(--pro-color-white-rgb);
    --ion-color-contrast: var(--pro-color-white-contrast);
    --ion-color-contrast-rgb: var(--pro-color-white-contrast-rgb);
    --ion-color-shade: var(--pro-color-white-shade);
    --ion-color-tint: var(--pro-color-white-tint);
  }
}

/* dark-mode definitions */
body.dark {
  .pro-new-design {
    /** Primary / Cosmic Purple night **/
    --ion-color-primary: #8680ff;
    --ion-color-primary-rgb: 134, 128, 255;
    --ion-color-primary-contrast: #000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #7671e0;
    --ion-color-primary-tint: #928dff;

    /** Secondary / Aquamarine **/
    --ion-color-secondary: #57d3d3;
    --ion-color-secondary-rgb: 87, 211, 211;
    --ion-color-secondary-contrast: #000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #4dbaba;
    --ion-color-secondary-tint: #68d7d7;

    /** Tertiary / Steel Gray **/
    --ion-color-tertiary: #a8a8a8;
    --ion-color-tertiary-rgb: 168, 168, 168;
    --ion-color-tertiary-contrast: #000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #949494;
    --ion-color-tertiary-tint: #b1b1b1;

    /** Success / Meadow night **/
    --ion-color-success: #05c98b;
    --ion-color-success-rgb: 5, 201, 139;
    --ion-color-success-contrast: #000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #04b17a;
    --ion-color-success-tint: #1ece97;

    /** Warning / Goldenrod **/
    --ion-color-warning: #facc15;
    --ion-color-warning-rgb: 250, 204, 21;
    --ion-color-warning-contrast: #000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #dcb412;
    --ion-color-warning-tint: #fbd12c;

    /** Error / Danger / Coral Red **/
    --ion-color-danger: #f75555;
    --ion-color-danger-rgb: 247, 85, 85;
    --ion-color-danger-contrast: #000;
    --ion-color-danger-contrast-rgb: 0, 0, 0;
    --ion-color-danger-shade: #d94b4b;
    --ion-color-danger-tint: #f86666;

    /** Light / Silk Lavender night **/
    --ion-color-light: #696c7b;
    --ion-color-light-rgb: 105, 108, 123;
    --ion-color-light-contrast: #fff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #5c5f6c;
    --ion-color-light-tint: #787b88;

    /** Medium / Silver Sky night **/
    --ion-color-medium: #5a5d6a;
    --ion-color-medium-rgb: 90, 93, 106;
    --ion-color-medium-contrast: #fff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #4f525d;
    --ion-color-medium-tint: #6b6d79;

    /** Dark / Stormy Sky night **/
    --ion-color-dark: #e3e2f2;
    --ion-color-dark-rgb: 227, 226, 242;
    --ion-color-dark-contrast: #000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #c8c7d5;
    --ion-color-dark-tint: #e6e5f3;

    /** Custom Colors 
        Primary Tint 400  night **/
    --pro-color-primary-tint-400: #302177;
    --pro-color-primary-tint-400-rgb: 48, 33, 119;
    --pro-color-primary-tint-400-contrast: #fff;
    --pro-color-primary-tint-400-contrast-rgb: 255, 255, 255;
    --pro-color-primary-tint-400-shade: #2a1d69;
    --pro-color-primary-tint-400-tint: #453785;

    /** Primary Tint 300  night **/
    --pro-color-primary-tint-300: #7c65c4;
    --pro-color-primary-tint-300-rgb: 124, 101, 196;
    --pro-color-primary-tint-300-contrast: #fff;
    --pro-color-primary-tint-300-contrast-rgb: 255, 255, 255;
    --pro-color-primary-tint-300-shade: #6d59ac;
    --pro-color-primary-tint-300-tint: #8974ca;

    /** Primary Tint 200  night **/
    --pro-color-primary-tint-200: #5e4a9b;
    --pro-color-primary-tint-200-rgb: 94, 74, 155;
    --pro-color-primary-tint-200-contrast: #fff;
    --pro-color-primary-tint-200-contrast-rgb: 255, 255, 255;
    --pro-color-primary-tint-200-shade: #534188;
    --pro-color-primary-tint-200-tint: #6e5ca5;

    /** Primary Tint 100  night **/
    --pro-color-primary-tint-100: #3e3d4c;
    --pro-color-primary-tint-100-rgb: 62, 61, 76;
    --pro-color-primary-tint-100-contrast: #fff;
    --pro-color-primary-tint-100-contrast-rgb: 255, 255, 255;
    --pro-color-primary-tint-100-shade: #373643;
    --pro-color-primary-tint-100-tint: #51505e;

    /** Secondary Tint 600 **/
    --ion-color-secondary-tint-600: #48b0b0;
    --ion-color-secondary-tint-600-rgb: 72, 176, 176;
    --ion-color-secondary-tint-600-contrast: #000;
    --ion-color-secondary-tint-600-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-tint-600-shade: #3f9b9b;
    --ion-color-secondary-tint-600-tint: #5ab8b8;

    /** Secondary Tint 400 **/
    --ion-color-secondary-tint-400: #7ef4f4;
    --ion-color-secondary-tint-400-rgb: 126, 244, 244;
    --ion-color-secondary-tint-400-contrast: #000;
    --ion-color-secondary-tint-400-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-tint-400-shade: #6fd7d7;
    --ion-color-secondary-tint-400-tint: #8bf5f5;

    /** Secondary Tint 300 **/
    --ion-color-secondary-tint-300: #9ef6f6;
    --ion-color-secondary-tint-300-rgb: 158, 246, 246;
    --ion-color-secondary-tint-300-contrast: #000;
    --ion-color-secondary-tint-300-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-tint-300-shade: #8bd8d8;
    --ion-color-secondary-tint-300-tint: #a8f7f7;

    /** Secondary Tint 200 night **/
    --ion-color-secondary-tint-200: #48b0b0;
    --ion-color-secondary-tint-200-rgb: 72, 176, 176;
    --ion-color-secondary-tint-200-contrast: #000;
    --ion-color-secondary-tint-200-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-tint-200-shade: #3f9b9b;
    --ion-color-secondary-tint-200-tint: #5ab8b8;

    /** Secondary Tint 100 **/
    --ion-color-secondary-tint-100: #d8fcfc;
    --ion-color-secondary-tint-100-rgb: 216, 252, 252;
    --ion-color-secondary-tint-100-contrast: #000;
    --ion-color-secondary-tint-100-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-tint-100-shade: #bedede;
    --ion-color-secondary-tint-100-tint: #dcfcfc;

    /** Black **/
    --pro-color-black: #faf8ff;
    --pro-color-black-rgb: 250, 248, 255;
    --pro-color-black-contrast: #000;
    --pro-color-black-contrast-rgb: 0, 0, 0;
    --pro-color-black-shade: #dcdae0;
    --pro-color-black-tint: #fbf9ff;

    /** White night **/
    --pro-color-white: #2b313a;
    --pro-color-white-rgb: 43, 49, 58;
    --pro-color-white-contrast: #fff;
    --pro-color-white-contrast-rgb: 255, 255, 255;
    --pro-color-white-shade: #262b33;
    --pro-color-white-tint: #40464e;

    /** Lilac Whisper night **/
    --pro-color-lilac-whisper: #212a37;
    --pro-color-lilac-whisper-rgb: 33, 42, 55;
    --pro-color-lilac-whisper-contrast: #fff;
    --pro-color-lilac-whisper-contrast-rgb: 255, 255, 255;
    --pro-color-lilac-whisper-shade: #1d2530;
    --pro-color-lilac-whisper-tint: #373f4b;

    /** Pearl White **/
    --pro-color-pearl-white: #f5f5f5;
    --pro-color-pearl-white-rgb: 245, 245, 245;
    --pro-color-pearl-white-contrast: #000;
    --pro-color-pearl-white-contrast-rgb: 0, 0, 0;
    --pro-color-pearl-white-shade: #d8d8d8;
    --pro-color-pearl-white-tint: #f6f6f6;

    /** Pink **/
    --pro-color-pink: #ea1e61;
    --pro-color-pink-rgb: 234, 30, 97;
    --pro-color-pink-contrast: #000;
    --pro-color-pink-contrast-rgb: 0, 0, 0;
    --pro-color-pink-shade: #ce1a55;
    --pro-color-pink-tint: #ec3571;
  }
}
