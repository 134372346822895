ion-popover.pro-popover {
  --backdrop-opacity: var(--ion-backdrop-opacity);

  overflow-y: scroll;

  &::part(content) {
    right: 0;
    left: auto !important;
    width: 93.4%;
    overflow: hidden;
    border-radius: 20px 0 0;
    background-color: var(--ion-item-background);
  }
}

ion-popover.pro-new-design {
  --backdrop-opacity: var(--ion-backdrop-opacity);
  --background: var(--pro-color-white);
  --width: calc(100vw - (2 * var(--ion-margin)));
  --max-width: 600px;
  --offset-x: calc(var(--ion-margin) - 5px);

  overflow-y: scroll;

  &::part(content) {
    border-radius: 44px;
  }
}
