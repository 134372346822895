ion-item {
  .sc-ion-input-ios-h,
  .sc-ion-input-ios-h.legacy-input {
    --placeholder-opacity: 0.33;

    align-items: flex-start;
  }
}

ion-input:not(.input-label-placement-floating),
ion-textarea,
ion-datetime {
  @include body-text;

  width: 100%;

  &.readonly {
    color: var(--ion-color-medium);
  }
}

ion-datetime {
  --padding-top: 0;
  --padding-bottom: 0;

  flex: 1 1 auto;
}

ion-picker {
  /* fix touchstart event error */
  touch-action: none;
}

.sc-ion-picker-ios-h {
  --background: var(--ion-item-background);

  .picker-button {
    @include body-text;

    color: var(--ion-text-color);
  }
}

ion-input:not(.input-label-placement-floating),
ion-select.legacy-select:not(.pro-select) {
  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
  --placeholder-opacity: 0.33;

  padding-top: 0 !important;
  padding-bottom: 0 !important;
  line-height: 1.24rem;
}

ion-select.legacy-select:not(.pro-select) {
  --padding-top: 0;
  --padding-bottom: 0;

  @include body-text;

  flex: 1 1 auto;
  align-items: flex-start;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 22px;

  &.invisible {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    max-width: none;
  }
}

ion-select.legacy-select::part(text) {
  @include body-text;
}

ion-select.legacy-select:not(.pro-select)::part(icon) {
  display: none;
}

.pro-select {
  --padding-start: 15px;
  --padding-end: 20px;

  background-color: var(--pro-color-white);
  width: fit-content;
  height: 44px;

  &::part(icon) {
    display: none;
  }

  &::part(placeholder)::after,
  &::part(text)::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 5px 0;
    border-color: var(--pro-color-black) transparent transparent;
    position: absolute;
    top: 17px;
    right: 5px;
  }
}

.value-content {
  @include body-text;

  display: flex;
  flex: 1 1 auto;
  margin-bottom: var(--ion-margin);
}

ion-checkbox {
  --checkmark-width: 3;
  --size: 32px;
  --border-width: 2px;
  --border-color: var(--pro-color-black);
  --checkbox-background: transparent;
  --checkbox-background-checked: var(--ion-color-primary);
  --checkmark-color: var(--pro-color-white);

  &.checkbox-checked {
    --border-width: 0;

    &::part(mark) {
      /* hides default checkmark-icon */
      opacity: 0;
    }

    &::part(container) {
      background-image: url('/assets/svgs/checkbox_checkmark_light.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 18px auto;
    }
  }
}

/* exception for dark-mode necessary because svgs used as background images ignore variable color values */
.dark ion-checkbox {
  &.checkbox-checked {
    &::part(container) {
      background-image: url('/assets/svgs/checkbox_checkmark_dark.svg');
    }
  }
}

ion-radio {
  --color: var(--pro-color-black);

  margin: 0;
  margin-inline-end: 12px;
  width: 32px;
  height: 32px;

  &.radio-checked::part(mark) {
    width: 18px;
    height: 18px;
  }
}

/* stylelint-disable no-descending-specificity */

/**
  NEW DESIGN
*/
.pro-new-design form {
  margin: 24px 0;
}

ion-input.ios.input-label-placement-floating,
ion-input.pro-input-single-char {
  --background: var(--ion-color-light);
  --border-radius: 16px;
  --border-width: 1px;
  --border-style: solid;
  --highlight-color-focused: var(--ion-text-color);
  --highlight-color-valid: var(--ion-text-color);
  --highlight-color-invalid: var(--ion-color-danger);
  --placeholder-color: var(--ion-color-dark);
  --placeholder-opacity: 1;
  --padding-start: 16px;
  --padding-end: 16px;
  --padding-bottom: 11px;
  --padding-top: 0;

  font-weight: 500;
  border: solid var(--ion-color-light) 1px;
  border-radius: 16px;
  transition: border-color 0.4s ease;

  .label-text-wrapper {
    color: var(--ion-color-dark);
  }

  &:not(.label-floating) .label-text-wrapper {
    transform: translateY(calc(100% - 7px)) scale(1);
  }

  &.label-floating {
    .label-text-wrapper {
      transform: translateY(9px) scale(0.76);
    }

    .native-wrapper {
      transform: translateY(4px);
    }
  }

  &.ion-invalid {
    --color: var(--ion-color-danger);

    border-color: var(--ion-color-danger);

    .label-text-wrapper {
      color: var(--ion-color-danger);
    }
  }

  &.input-disabled {
    --color: var(--ion-color-tertiary);

    opacity: 1;

    .label-text-wrapper {
      color: var(--ion-color-tertiary);
    }
  }

  &.mandatory {
    .label-text-wrapper::after {
      content: '*';
      color: var(--pro-color-pink);
      padding-left: 4px;
    }
  }
}

ion-input.pro-input-single-char {
  --padding-start: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --padding-top: 0;
  --border-radius: 8px;

  height: 80px;
  border-radius: 8px;
  border-width: 0;

  .input-wrapper {
    /* 42px / 63px */
    font-size: 2.41rem;
    line-height: 3.71rem;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.005em;
    border: solid var(--ion-color-medium) 1px;
  }

  &.has-focus {
    .input-wrapper {
      outline: 4px solid var(--pro-color-secondary-tint-200);
      border: solid var(--pro-color-secondary-tint-400) 1px;
    }
  }
}

ion-textarea.ios.textarea-label-placement-floating {
  --background: var(--ion-color-light);
  --border-radius: 16px;
  --border-width: 1px;
  --border-style: solid;
  --highlight-color-focused: var(--ion-text-color);
  --highlight-color-valid: var(--ion-text-color);
  --highlight-color-invalid: var(--ion-color-danger);
  --placeholder-color: var(--ion-color-dark);
  --placeholder-opacity: 1;
  --padding-start: 16px;
  --padding-end: 16px;
  --padding-bottom: 4px;
  --padding-top: 0;

  border: solid var(--ion-color-light) 1px;
  border-radius: 16px;
  transition: border-color 0.4s ease;

  .native-textarea {
    padding-bottom: 0;
    margin-top: 1px;
  }

  .textarea-wrapper {
    min-height: 0;
  }

  &:not(.label-floating) {
    .label-text-wrapper {
      transform: translateY(calc(100% - 7px)) scale(1);
    }
  }

  &.label-floating {
    .label-text-wrapper {
      transform: translateY(9px) scale(0.76);
    }
  }
}

ion-select.ios.select-label-placement-floating {
  --background: var(--ion-color-light);
  --border-radius: 16px;
  --border-width: 1px;
  --border-style: solid;
  --border-color: green;
  --highlight-color-focused: var(--ion-text-color);
  --highlight-color-valid: var(--ion-text-color);
  --highlight-color-invalid: var(--ion-color-danger);
  --placeholder-color: var(--ion-color-dark);
  --placeholder-opacity: 1;
  --padding-start: 16px;
  --padding-end: 16px;
  --padding-bottom: 9.5px;
  --padding-top: 0;

  font-weight: 500;
  border: solid var(--ion-color-light) 1px;
  border-radius: 16px;
  transition: border-color 0.4s ease;

  &::part(label) {
    color: var(--ion-color-dark);
  }

  &::part(container) {
    /* use the line-height-value as min-height to avoid a height of 0 on an empty select-input */
    min-height: 1.5rem;
    margin-top: 3px;
  }

  &::part(icon) {
    display: none;
  }

  &:not(.label-floating) {
    &::part(label) {
      transform: translateY(calc(100% - 7px)) scale(1);
    }
  }

  &.label-floating {
    &::part(label) {
      transform: translateY(9px) scale(0.76);
    }
  }

  &.ion-invalid {
    border-color: var(--ion-color-danger);

    &::part(label) {
      color: var(--ion-color-danger);
    }
  }

  &.mandatory {
    &::part(label)::after {
      content: '*';
      color: var(--pro-color-pink);
      padding-left: 4px;
    }
  }
}

ion-checkbox.md {
  --border-color: var(--ion-color-primary);
  --border-color-checked: var(--ion-color-primary);
  --border-radius: 12px;
  --border-width: 4px;
  --checkbox-background: transparent;
  --checkbox-background-checked: var(--ion-color-primary);
  --size: 32px;

  &.checkbox-checked {
    --border-width: 0;

    &::part(mark) {
      /* hides default checkmark-icon */
      opacity: 0;
    }

    &::part(container) {
      background-image: url('/assets/svgs/checkbox_checkmark_light_new.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16px auto;
      transition: none;
    }
  }

  &::part(label) {
    /* 17px / 25.5px */
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.2px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0;
    text-overflow: unset;
    white-space: normal;
  }

  .sub-label {
    /* 13px / 19.5px */
    font-size: 0.77rem;
    line-height: 1.15rem;
    display: block;
    font-weight: 500;
    padding-top: 8px;
  }
}

ion-radio:not(.legacy-radio) {
  --color: var(--ion-text-color);
  --color-checked: var(--ion-color-primary);

  width: auto;
  height: auto;
  margin-inline-end: auto;

  &::part(container) {
    width: 32px;
    height: 32px;
  }

  &::part(mark) {
    width: 18px;
    height: 18px;
  }

  &::part(label) {
    white-space: unset;
  }

  &.radio-checked::part(label) {
    color: var(--ion-color-primary);
  }
}

.form-error-message {
  /* 14px / 19.12px */
  font-size: 0.82rem;
  line-height: 1.12rem;
  font-weight: 500;
  color: var(--ion-color-danger);
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;

  &::before {
    content: '';
    display: inline-block;
    flex: 0 0 28px;
    width: 28px;
    height: 24px;
    background-image: url('/assets/svgs/warning-form-error.svg');
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 24px auto;
  }
}

.input-label-standalone {
  /* 13px / 19.5px */
  font-size: 0.77rem;
  line-height: 1.15rem;
  display: block;
  font-weight: 500;
  padding-bottom: 8px;
}

.input-info-trigger {
  position: absolute;
  right: 0;
  height: 100%;
  font-size: 19px;
  padding: 0 24px 0 12px;
  z-index: 12;
  margin: 0;
  cursor: pointer;
}
