ion-modal.datetime-modal.modal-default,
ion-modal.datetime-modal.modal-default:not(.overlay-hidden) {
  --backdrop-opacity: 1 !important;
}

.floating-modal:not(.pro-new-design) {
  --height: 50%;
  --min-height: 280px;

  .modal-wrapper {
    border-radius: 13px 0 0 13px;
    margin-left: 6.6%;
  }

  .textarea-wrapper {
    height: 100%;
  }

  .native-textarea {
    height: 100%;

    &:disabled {
      opacity: 1;
    }
  }
}

.pro-modal {
  ion-select::part('icon') {
    display: none;
  }

  .error {
    font-size: 12px;
    font-weight: 700;
    margin: 11px 22px 0;
  }
}

.info-modal {
  align-items: flex-end;
  justify-content: flex-end;

  --height: calc(100vh - 42px);
  --width: calc(100vw - 25px);

  &::part(content) {
    background: transparent;
  }
}

.message-toast {
  &::part(message) {
    white-space: pre-line;
  }
}

.modal-sheet {
  --border-radius: 20px;

  ion-content {
    --background: var(--ion-color-white);
  }

  &::part(handle) {
    background: var(--ion-color-light);
    width: 44px;
    height: 4px;
    top: 8px;
    border-radius: 2px;
  }

  &::part(content) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

/* New style for floating-modals */
ion-modal.pro-new-design {
  &.auto-height {
    --height: auto;
  }

  &.floating-modal {
    --height: clamp(340px, 50%, calc(100vh - (2 * 24px)));
    --min-height: 280px;
    --max-height: calc(100vh - (2 * 24px));
    --max-width: clamp(340px, calc(100vw - (2 * 24px)), 480px);
    --border-radius: 44px;

    ion-content {
      --padding-start: 0;
      --padding-end: 0;
      --padding-top: 0;
      --padding-bottom: 0;
    }

    > .ion-page {
      position: absolute;
      padding: 40px 32px 16px;
    }
  }

  ion-textarea {
    .counter {
      position: absolute;
      right: 16px;
      top: 7px;
    }

    .textarea-bottom {
      padding: 0;
      border: none;
    }
  }
}
