.fab-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.fab-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

ion-fab {
  padding-right: 15px;
  padding-bottom: 15px;
}

ion-fab-button {
  width: 80px;
  height: 80px;

  ion-icon {
    --ionicon-stroke-width: 52px;

    font-size: 45px;
  }
}
