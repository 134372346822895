swiper-container {
  swiper-slide {
    display: flex;
    text-align: left;
    justify-content: flex-start;
    align-items: stretch;
    overflow-y: auto;
    flex-direction: column;
  }
}
