.chat-bubble {
  p {
    padding-right: 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    font-weight: bold;
    text-decoration: underline;
  }

  ul {
    margin: 8px 0 16px;

    li {
      margin: 4px 0;
    }

    li::marker {
      color: var(--ion-text-color);
    }
  }

  .retrival-image {
    display: block;
    width: 40%;
    margin-top: 8px;
  }

  &.result {
    .content span {
      display: block;
      margin-bottom: 4px;
    }
  }
}
