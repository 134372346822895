/* stylelint-disable no-descending-specificity */
.pro-alert:not(.pro-new-design) {
  --background: var(--ion-item-background);
  --max-width: 100%;
  --width: 100%;

  .alert-wrapper {
    margin-left: 6%;
    border-radius: 20px 0 0;
  }

  .alert-head {
    padding: 24px 24px 16px;
    text-align: left;
  }

  .alert-title {
    /* 19px / 22px */
    font-size: 1.12rem;
    line-height: 1.3rem;
    font-weight: 700;
    margin: 0;
  }

  .alert-message {
    @include body-text;

    padding: 0 24px 24px;
    text-align: left;
    max-height: 60vh;

    p {
      margin: 0;
      padding: 0;
    }
  }

  .alert-button-group {
    .alert-button {
      @include modal-bottom-buttons;

      flex: 1;
    }
  }

  .alert-icon {
    font-size: 180px;
  }

  &.warning {
    .alert-button:last-of-type {
      background-color: var(--ion-color-warning);
      color: var(--ion-color-warning-contrast);
    }
  }

  &.success {
    .alert-title {
      color: var(--ion-color-secondary);
    }

    .alert-head,
    .alert-message {
      text-align: center;
    }

    .alert-message img {
      margin: 16px 0;
    }
  }

  &.spinner {
    ion-spinner {
      display: block;
      margin: 0 auto;
      color: var(--ion-color-primary);
      width: 48px;
      height: 48px;
    }
  }
}

/**
NEW DESIGN
*/
.pro-alert.pro-new-design {
  --background: var(--ion-background-color);
  --max-width: clamp(60vw, calc(100vw - (2 * 24px)), 98vw);

  .alert-wrapper {
    border-radius: 44px;
    overflow: auto;
  }

  .alert-head {
    padding: 40px 32px 16px;
    text-align: center;
  }

  .alert-title {
    /* 29px / 43.5px */
    font-size: 1.71rem;
    line-height: 2.56rem;
    font-weight: 600;
    margin: 0;
    color: var(--ion-color-primary);
  }

  .alert-message {
    /* 17px / 25.5px */
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.2px;
    font-weight: 500;
    padding: 0 32px 32px;
    text-align: center;
    max-height: 60vh;

    p {
      margin: 0;
      padding: 0;
    }

    /* TODO: move rule into the p rule above */
    p + p {
      margin-top: 16px;
    }
  }

  .alert-button-group {
    padding: 0 32px 32px;

    .alert-button {
      /* 15px / 22.5px */
      font-size: 0.88rem;
      line-height: 1.32rem;
      font-weight: 600;
      background-color: var(--ion-color-primary);
      border-radius: 100px;
      border-color: var(--pro-color-primary-tint-300);
      border-style: solid;
      border-width: 0 0 5px;
      color: var(--pro-color-white);
      padding: 14px 16px;
      height: auto;
      flex: 1 0 100%;
    }

    .alert-button + .alert-button {
      background: var(--pro-color-white);
      color: var(--ion-color-primary);
      border-color: var(--ion-color-medium);
      margin-top: 12px;
    }
  }
}
