/* stylelint-disable selector-class-pattern */
.pro-sv-container {
  font-size: 1.06rem;
  line-height: 1.24rem;
  color: var(--ion-text-color);
  margin-top: 10px;

  h5 {
    .sv_q_num {
      display: none;
    }
  }

  .sv_qstn {
    overflow: visible; /* necessary for sv_qbc box-shadow */

    h5 {
      font-size: 1.12rem;
      line-height: 1.3rem;
      color: var(--ion-color-secondary);
      font-weight: 800;
      padding-right: 6%;
    }

    p {
      padding-right: 6%;
    }

    div > b {
      padding-right: 6%;
      display: inline-block;
      margin-bottom: 18px;
    }

    .sv_q_description {
      font-size: 0.94rem;
      line-height: 1.24rem;
      font-family: Helvetica, Gilroy, sans-serif;
      font-style: italic;
      padding-right: 6%;
    }
  }

  .sv_qstn .sv-q-col-1,
  .sv-question .sv-q-col-1 {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
  }
}

.pro-sv-error-root {
  color: var(--ion-color-warning);
  font-weight: 500;
  padding-right: 6%;
}

.pro-sv_header__text {
  max-width: none !important;
  font-size: 1.12rem;
  line-height: 1.3rem;
  font-weight: 700;
  color: var(--ion-text-color);
}

.pro-sv-footer {
  margin-top: 42px;
  display: flex;

  input {
    border-width: 0;
    font-size: 0.82rem;
    line-height: 0.97rem;
    letter-spacing: 1.3px;
    font-weight: 800;
    text-transform: uppercase;
  }

  input:focus {
    outline: none;
  }

  & > * {
    flex-grow: 1;
    flex-basis: 0;
  }
}

.pro-sv-next,
.pro-sv-button {
  background-color: var(--ion-color-primary);
  height: auto;
  padding: 21px 0;
  border-radius: 40px 0 0 40px;
  color: var(--pro-color-white);
}

.pro-sv-button-light {
  background-color: transparent;
  color: var(--ion-text-color);
}

.pro-sv-button-secondary {
  background-color: transparent;
  color: var(--ion-text-color);
}

.pro-sv-container .sv_qcbc {
  margin-top: 20px;
  box-shadow: var(--pro-default-shadow);
  background-color: var(--ion-item-background);
  border-radius: 20px 0 0;
}

.pro-sv-container-rating .sv_q_radiogroup {
  .sv_q_radiogroup_label {
    .circle {
      text-align: center;
    }

    .number {
      font-size: 1rem;
      font-weight: 500;
      color: var(--ion-text-color);
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      white-space: nowrap;
    }
  }
}

.pro-sv-container .sv_q_radiogroup {
  min-height: 60px;
  padding: 10px 8px 10px 20px;
  border-bottom: var(--pro-default-border);
  font-size: 1.06rem;
  line-height: 1.24rem;
  color: var(--ion-text-color);
  font-weight: 500;

  &:last-of-type {
    border-bottom-width: 0;
  }

  .sv_q_radiogroup_label {
    display: flex;
    height: 100%;
    position: relative;
    flex: 1;

    input {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    & > span:first-of-type {
      display: table;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      border: solid 2px var(--ion-text-color);
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .check {
      opacity: 0;
      transform: rotate(45deg);
      border-width: 0 2px 2px 0;
      border-style: solid;
      border-color: var(--pro-color-white);
      width: 8px;
      height: 14px;
      display: block;
      position: absolute;
      top: 50%;
      margin-left: 10px;
      margin-top: -8px;
    }

    span:last-of-type:not(.number) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 20px;
    }
  }
}

.pro-sv-container .sv_q_rating {
  display: flex;
  justify-content: space-between;
  padding-right: 6%;
  margin: 25px 0;

  label {
    width: 35px;
    height: 35px;
    color: var(--ion-color-secondary);
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border: 2px solid var(--ion-color-secondary);
    border-radius: 50%;

    &.active {
      background-color: var(--ion-color-secondary);
      border-color: var(--ion-color-secondary);
      color: var(--pro-color-white);
    }
  }
}

.pro-sv-container .sv_q_radiogroup.checked {
  .sv_q_radiogroup_label {
    & > span:first-of-type {
      background-color: var(--ion-color-primary);
      border-width: 0;
    }

    .check {
      opacity: 1;
    }
  }
}

.pro-sv-container-rating .sv_q_radiogroup.checked {
  .sv_q_radiogroup_label {
    .check {
      opacity: 0;
    }

    .number {
      color: var(--pro-color-white);
    }
  }
}

.pro-sv-label-html {
  display: inline !important;
  width: 100%;
  margin-left: 0 !important;
}

/* noUiSlider styles */
.pro-noUi-container {
  position: relative;
  padding: 52px 0 !important;
  box-shadow: var(--pro-default-shadow);
  background-color: var(--ion-item-background);
  border-radius: 20px 0 0;
}

.noUi-vertical {
  margin-left: auto;
  margin-right: auto;
  height: 300px !important;
  width: 10px;
  transform: translateX(-24px);
}

.noUi-handle {
  box-shadow: var(--pro-default-shadow);
  border-width: 0;
}

.noUi-vertical .noUi-handle {
  width: 40px;
  height: 40px;
  left: -16px;
  border-radius: 50%;
  background-color: var(--ion-color-primary);
}

.noUi-target.noUi-rtl.noUi-vertical {
  margin-bottom: 0 !important;
}

.noUi-vertical .noUi-handle::after,
.noUi-vertical .noUi-handle::before {
  height: 0;
}

.noUi-connect {
  background: var(--ion-color-primary);
  bottom: -2px !important;
}

.noUi-target {
  background-color: var(--ion-color-medium);
  border: none;
  border-radius: 0;
}

.noUi-pips-vertical {
  padding: 0;
  top: 2px;
  right: 150%;
  left: auto;
}

.noUi-marker,
.noUi-marker-large,
.noUi-marker-sub {
  background-color: var(--ion-color-medium);
}

.noUi-marker-vertical.noUi-marker {
  width: 7px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 25px;
  left: -10px;
}

.noUi-value {
  color: var(--ion-text-color);
  font-size: 1rem;
  line-height: 1.24rem;
  font-weight: bold;
}

.noUi-value-vertical {
  right: 15px;
}

.noUi-tooltip {
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 800;
  color: var(--ion-color-primary);
  border: none;
}

.noUi-vertical .noUi-tooltip {
  left: 120%;
  right: auto;
  padding: 0;
}

.noUi-max-rate-description,
.noUi-min-rate-description {
  position: absolute;
  left: 50%;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  color: var(--ion-color-primary);
  padding: 5px;
}

.noUi-max-rate-description {
  top: 0;
}

.noUi-min-rate-description {
  bottom: 0;
}
