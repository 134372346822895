@font-face {
  font-family: Gilroy;
  src: url('~src/assets/fonts/Gilroy-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  src: url('~src/assets/fonts/Gilroy-Medium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  src: url('~src/assets/fonts/Gilroy-Bold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  src: url('~src/assets/fonts/Gilroy-ExtraBold.otf');
  font-weight: 900;
  font-style: normal;
}

/* global body-text style
   17px / 21px */
@mixin body-text {
  font-size: 1rem !important;
  line-height: 1.24rem !important;
  letter-spacing: 0 !important;
  font-weight: 500 !important;
}

.body-text {
  @include body-text;
}

/**
  Poppins
*/
@font-face {
  font-family: Poppins;
  src: url('~src/assets/fonts/Poppins-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url('~src/assets/fonts/Poppins-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url('~src/assets/fonts/Poppins-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url('~src/assets/fonts/Poppins-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url('~src/assets/fonts/Poppins-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url('~src/assets/fonts/Poppins-Black.ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Poppins;
  src: url('~src/assets/fonts/Poppins-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('~src/assets/fonts/Poppins-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('~src/assets/fonts/Poppins-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('~src/assets/fonts/Poppins-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('~src/assets/fonts/Poppins-ExtraBoldItalic.ttf');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: Poppins;
  src: url('~src/assets/fonts/Poppins-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}
