ion-footer {
  background: var(--ion-background-color);
  padding: 16px 0 24px;

  ion-toolbar {
    --background: transparent;
    --padding-top: 0;
    --padding-end: 0;
    --padding-bottom: 0;
    --padding-start: 0;

    padding: 0;

    ion-button:only-of-type {
      width: 100%;
      padding-left: var(--pro-content-padding);
    }
  }
}

ion-content + ion-footer {
  background: transparent;

  &::before {
    position: absolute;
    content: '';
    inset: 0;
    backdrop-filter: blur(7.5px);
  }
}

ion-footer.footer-ios ion-toolbar:first-of-type {
  --border-width: 0;
}

.keyboard-open ion-footer {
  padding-bottom: 0;
}

/* stylelint-disable no-descending-specificity */

/**
  NEW DESIGN
*/
.pro-new-design ion-footer {
  ion-toolbar {
    --background: transparent;
    --padding-top: 0;
    --padding-end: var(--pro-content-padding);
    --padding-bottom: 0;
    --padding-start: var(--pro-content-padding);

    ion-buttons {
      flex: 1 1 100%;
      margin-right: 16px;
    }

    ion-buttons ion-button {
      flex: 1;
      padding: 0;
    }

    ion-buttons:last-of-type {
      margin-right: 0;
    }
  }
}
