/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable function-no-unknown */

/* Custom Theming for Angular Material */
/* stylelint-disable-next-line at-rule-no-unknown */
@use '@angular/material' as mat;

/* For more information: https://material.angular.io/guide/theming */
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import 'palette';

/* Include the common styles for Angular Material. We include this here so that you only
  have to load a single css file for Angular Material in your app.
  Be sure that you only ever include this mixin once! */
@include mat.core();

/* Define the palettes for your theme using the Material Design palettes available in palette.scss
   (imported above). For each palette, you can optionally specify a default, lighter, and darker
   hue. Available color palettes: https://material.io/design/color/ */
$app-primary: mat.define-palette($pro-primary, 500, 50, 900);
$app-accent: mat.define-palette($pro-secondary, 500, 50, 900);

/* The warn palette is optional (defaults to red). */
$app-warn: mat.define-palette($pro-red, 500);

/* Create the theme object (a Sass map containing all of the palettes). */
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
    typography:
      mat.define-typography-config(
        $font-family: 'Gilroy',
      ),
    density: 0,
  )
);
$tabs-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-warn,
      accent: $app-warn,
    ),
  )
);

/* Define a custom typography config that overrides the font-family as well as the
   `headlines` and `body-1` levels. */
$custom-typography: mat.define-legacy-typography-config(
  $font-family: 'Gilroy',
);

/* Include theme styles for core and each component used in your app.
   Alternatively, you can import and @include the theme mixins for each component
   that you are using. */
@include mat.all-component-themes($app-theme);

/* Custom Material Classes */
.pro-tab-group {
  &.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
    position: relative;
    flex-grow: 0;
  }

  .mat-mdc-tab-header {
    border-width: 0;
  }

  .mat-mdc-tab-labels .mdc-tab__content {
    justify-content: start;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    padding-bottom: 5px;
    border-bottom: var(--pro-default-border);
    color: $grey-light;
  }

  .mat-mdc-tab .mdc-tab__text-label {
    color: var(--ion-color-primary);
  }

  .mdc-tab-indicator {
    max-width: 65px;
  }

  .mdc-tab-indicator .mdc-tab-indicator__content--underline {
    border-top-width: 1px;
  }

  .mdc-tab-indicator--active .mdc-tab__content {
    color: mat.get-color-from-palette($app-warn);
    font-weight: 900;
  }

  .mat-mdc-tab.mdc-tab {
    padding: 0 !important;
    margin: 0 5px;
    min-width: 65px;
    opacity: 1;
    line-height: 17px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .mat-mdc-tab-body {
    padding-top: 15px;
  }

  .pro-tab-header {
    color: mat.get-color-from-palette($app-warn);
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 25px;
  }

  &.min-height .mat-mdc-tab-body {
    min-height: 500px;
  }

  &.no-scroll .mat-mdc-tab-body-content {
    overflow: hidden;
  }

  &.secondary {
    .mdc-tab {
      font-weight: 500;
      margin: 0;
    }

    .mdc-tab__content {
      padding-right: 12px;
      padding-left: 3px;
      color: var(--ion-color-primary);
    }
  }

  &.full-height {
    .mat-mdc-tab-body-wrapper {
      flex: 1;
    }
  }

  &.full-width {
    .mat-tab-label {
      flex: 1 0 auto;
    }
  }
}

mat-stepper.pro-horizontal-stepper {
  text-align: center;

  .mat-horizontal-stepper-header-container {
    max-width: 204px;
    margin: auto;
  }

  &.mat-stepper-horizontal {
    background-color: transparent;
  }

  .mat-horizontal-content-container {
    padding-left: 6.6%;
    padding-right: 0;
    text-align: left;
  }

  .mat-horizontal-stepper-header:not(:first-child)::before,
  .mat-horizontal-stepper-header:not(:last-child)::after {
    width: calc(100% - 45px);
    top: 38px;
  }

  .mat-step-icon {
    width: 28px;
    height: 28px;
  }

  .mat-step-header .mat-step-icon {
    background-color: var(--ion-color-medium);
    color: var(--ion-text-color);
    z-index: 1;
  }

  .mat-step-header .mat-step-icon-selected {
    background-color: var(--ion-color-primary);
    color: var(--pro-color-white);
  }

  .mat-step-header .mat-step-icon:not(.mat-step-icon-selected) {
    z-index: 1;
  }

  .mat-step-label,
  .mat-step-label-selected {
    font-size: 0.706rem; /* 12px */
  }

  .mat-step-header.cdk-keyboard-focused,
  .mat-step-header.cdk-program-focused,
  .mat-step-header:hover {
    background-color: transparent;
  }

  &.full-width {
    .mat-horizontal-stepper-header-container {
      max-width: 100%;
    }

    .mat-horizontal-stepper-header:first-of-type {
      padding-left: 6.6%;
    }

    .mat-horizontal-stepper-header:last-of-type {
      padding-right: 6.6%;
    }

    .mat-horizontal-content-container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.label-disabled .mat-step-label {
    display: none;
  }
}

mat-step-header.pro-step-header-selected {
  &.mat-horizontal-stepper-header:not(:first-child)::before {
    border-top-color: var(--ion-color-primary);
  }
}

mat-step-header.pro-step-header-previous-step {
  &.mat-step-header .mat-step-icon {
    background-color: var(--ion-color-primary);
    color: var(--pro-color-white);
  }

  &.mat-horizontal-stepper-header:not(:first-child)::before,
  &.mat-horizontal-stepper-header:not(:last-child)::after {
    border-top-color: var(--ion-color-primary);
  }
}

mat-radio-group.pro-radio-group {
  .mdc-radio {
    padding: 0;
  }

  .mdc-radio__background {
    height: 22px;
    width: 22px;
  }

  .mdc-radio__outer-circle {
    height: 22px;
    width: 22px;
    border-width: 1px !important;
  }

  .mdc-radio__inner-circle {
    background-color: var(--ion-color-primary);
    height: 28px;
    width: 28px;
    top: -3px;
    left: -3px;
  }

  .mdc-form-field > label {
    padding-left: 8px;
  }
}

.mdc-form-field > label {
  font-size: 1rem;
  line-height: 1.24rem;
  letter-spacing: 0;
  font-weight: 500;
  color: var(--ion-text-color);
}

.loader {
  border: 2px solid var(--ion-background-color);
  border-radius: 50%;
  border-top: 2px solid var(--ion-color-primary);
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
