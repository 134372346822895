/**
Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ 
**/

/** Ionic CSS Variables **/
:root {
  /** primary / swiss plum **/
  --ion-color-primary: #564ab1;
  --ion-color-primary-rgb: 86, 74, 177;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #393176;
  --ion-color-primary-tint: #aba5d8;

  /** secondary / green chill **/
  --ion-color-secondary: #228585;
  --ion-color-secondary-rgb: 34, 133, 133;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1e7575;
  --ion-color-secondary-tint: #389191;

  /** tertiary / peach  **/
  --ion-color-tertiary: #ffb87c;
  --ion-color-tertiary-rgb: 255, 184, 124;
  --ion-color-tertiary-contrast: #000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #e0a26d;
  --ion-color-tertiary-tint: #ffbf89;

  /** success /  loona moona **/
  --ion-color-success: #71c3c3;
  --ion-color-success-rgb: 113, 195, 195;
  --ion-color-success-contrast: #000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #63acac;
  --ion-color-success-tint: #7fc9c9;

  /** warning / mat ruby  **/
  --ion-color-warning: #dd3447;
  --ion-color-warning-rgb: 221, 52, 71;
  --ion-color-warning-contrast: #fff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #c22e3e;
  --ion-color-warning-tint: #e04859;

  /** danger / not defined in figma yet **/
  --ion-color-danger: #ff919d;
  --ion-color-danger-rgb: 255, 145, 157;
  --ion-color-danger-contrast: #000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #e0808a;
  --ion-color-danger-tint: #ff9ca7;

  /** dark / mine shaft **/
  --ion-color-dark: #383838;
  --ion-color-dark-rgb: 56, 56, 56;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #313131;
  --ion-color-dark-tint: #4c4c4c;

  /** medium / magnesium **/
  --ion-color-medium: #c2c2c2;
  --ion-color-medium-rgb: 194, 194, 194;
  --ion-color-medium-contrast: #000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #ababab;
  --ion-color-medium-tint: #c8c8c8;

  /** light / mercury **/
  --ion-color-light: #e5e5e5;
  --ion-color-light-rgb: 229, 229, 229;
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #cacaca;
  --ion-color-light-tint: #e8e8e8;

  /** ionic colors **/
  --ion-background-color: var(--pro-color-zircon);
  --ion-background-color-rgb: var(--pro-color-zircon-rgb);
  --ion-text-color: var(--pro-color-black);
  --ion-text-color-rgb: var(--pro-color-black-rgb);
  --ion-item-background: var(--pro-color-white);
  --ion-item-color: var(--ion-text-color);
  --ion-border-color: rgb(162 163 194 / 30%);
  --ion-box-shadow-color: rgb(0 0 0 / 10%);
  --ion-backdrop-color: var(--pro-color-black);

  /** ionic stepped colors **/
  --ion-color-step-50: #e8ecf2;
  --ion-color-step-100: #dcdfe6;
  --ion-color-step-150: #cfd3d9;
  --ion-color-step-200: #c3c6cc;
  --ion-color-step-250: #b7babf;
  --ion-color-step-300: #abaeb3;
  --ion-color-step-350: #9fa1a6;
  --ion-color-step-400: #929599;
  --ion-color-step-450: #86888c;
  --ion-color-step-500: #7a7c80;
  --ion-color-step-550: #6e7073;
  --ion-color-step-600: #626366;
  --ion-color-step-650: #555759;
  --ion-color-step-700: #494a4d;
  --ion-color-step-750: #3d3e40;
  --ion-color-step-800: #313233;
  --ion-color-step-850: #252526;
  --ion-color-step-900: #181919;
  --ion-color-step-950: #0c0c0d;

  /** Custom Colors
      black **/
  --pro-color-black: #000;
  --pro-color-black-rgb: 0, 0, 0;
  --pro-color-black-contrast: #fff;
  --pro-color-black-contrast-rgb: 255, 255, 255;
  --pro-color-black-shade: #000;
  --pro-color-black-tint: #1a1a1a;

  /** white **/
  --pro-color-white: #fff;
  --pro-color-white-rgb: 255, 255, 255;
  --pro-color-white-contrast: #000;
  --pro-color-white-contrast-rgb: 0, 0, 0;
  --pro-color-white-shade: #e0e0e0;
  --pro-color-white-tint: #fff;

  /** cranberry **/
  --pro-color-cranberry: #d74c82;
  --pro-color-cranberry-rgb: 215 76 130;
  --pro-color-cranberry-contrast: #000;
  --pro-color-cranberry-contrast-rgb: 0, 0, 0;
  --pro-color-cranberry-shade: #bd4372;
  --pro-color-cranberry-tint: #db5e8f;

  /** blue-velvet **/
  --pro-color-blue-velvet: #59759f;
  --pro-color-blue-velvet-rgb: 89, 117, 159;
  --pro-color-blue-velvet-contrast: #fff;
  --pro-color-blue-velvet-contrast-rgb: 255, 255, 255;
  --pro-color-blue-velvet-shade: #4e678c;
  --pro-color-blue-velvet-tint: #6a83a9;

  /** egg yolk sunrise **/
  --pro-color-egg-yolk-sunrise: #ffd60a;
  --pro-color-egg-yolk-sunrise-rgb: 255, 214, 10;
  --pro-color-egg-yolk-sunrise-contrast: #000;
  --pro-color-egg-yolk-sunrise-contrast-rgb: 0, 0, 0;
  --pro-color-egg-yolk-sunrise-shade: #e0bc09;
  --pro-color-egg-yolk-sunrise-tint: #ffda23;

  /** zircon **/
  --pro-color-zircon: #f4f8ff;
  --pro-color-zircon-rgb: 244, 248, 255;
  --pro-color-zircon-contrast: #000;
  --pro-color-zircon-contrast-rgb: 0, 0, 0;
  --pro-color-zircon-shade: #d7dae0;
  --pro-color-zircon-tint: #f5f9ff;

  /** zircon night **/
  --pro-color-zircon-night: #0c244d;
  --pro-color-zircon-night-rgb: 12, 36, 77;
  --pro-color-zircon-night-contrast: #fff;
  --pro-color-zircon-night-contrast-rgb: 255, 255, 255;
  --pro-color-zircon-night-shade: #0b2044;
  --pro-color-zircon-night-tint: #243a5f;

  /** hawkes blue **/
  --pro-color-hawkes-blue: #dfeafd;
  --pro-color-hawkes-blue-rgb: 223, 234, 253;
  --pro-color-hawkes-blue-contrast: #000;
  --pro-color-hawkes-blue-contrast-rgb: 0, 0, 0;
  --pro-color-hawkes-blue-shade: #c4cedf;
  --pro-color-hawkes-blue-tint: #e2ecfd;

  /** ionic application vars **/
  --ion-font-family: 'Gilroy';
  --ion-margin: 8px;
  --ion-padding: 8px;
  --ion-backdrop-opacity: 0.5;
  --pro-nav-bar-height: 80px;

  /** custom app vars **/
  --pro-content-padding: 6.6%;
  --pro-default-border: solid 0.55px var(--ion-border-color);
  --pro-blurry-footer-background: rgb(244 248 255 / 40%);

  /** gradients **/
  --pro-gradient-swiss-plum: linear-gradient(180deg, #5348a7 0%, #7566e1 72.5%);
  --pro-gradient-swiss-plum-horizontal: linear-gradient(
    90deg,
    rgb(83 72 167 / 95%) 13.91%,
    rgb(117 102 225 / 95%) 92.35%
  );
  --pro-gradient-green-chill-horizontal: linear-gradient(93deg, #3d9090 0%, #5ac8c8 100%);
  --pro-gradient-green-chill: linear-gradient(180deg, #3d9090 0%, #71c3c3 100%);
  --pro-gradient-green-chill-2: linear-gradient(270deg, #228585 0%, #176969 100%);
  --pro-gradient-peach: linear-gradient(269deg, #ffd4af 0%, #ffb87c 100%);
  --pro-gradient-message: linear-gradient(
    338deg,
    rgba(86 74 177 / 85%) 8.81%,
    rgba(159 147 254 / 85%) 85.3%
  );
  --pro-gradient-message-card-small-top-unread: linear-gradient(
    178deg,
    #a79efa -11.07%,
    rgba(167 158 250 / 51%) 48.47%,
    rgba(168 157 250 / 0%) 73.6%
  );
  --pro-gradient-message-card-small-bottom-unread: linear-gradient(
    0deg,
    rgba(0 0 0 / 40%) 4.55%,
    rgba(0 0 0 / 30%) 62.46%,
    rgba(0 0 0 / 0%) 100.6%
  );
  --pro-gradient-message-card-small-bottom-read: linear-gradient(
    0deg,
    rgba(255 255 255 / 60%) 4.05%,
    rgba(255 255 255 / 0%) 71.35%
  );
  --pro-gradient-message-card-big-unread: linear-gradient(45deg, #564ab1 0%, #9f93fe 92.5%);
  --pro-gradient-message-card-big-overlay-unread: linear-gradient(
    94deg,
    rgba(86 74 177 / 85%) 4.03%,
    rgba(112 100 205 / 54%) 61.52%,
    rgba(159 147 254 / 0%) 88.32%
  );

  /** box-shadows **/
  --pro-nav-bar-shadow: 0 4px 8px 0 rgb(0 0 0 / 25%);
  --pro-default-shadow: 0 2px 15px 0 rgb(0 0 0 / 10%);
  --pro-measurement-cta-shadow: 0 8px 8px 0 rgb(86 74 177 / 40%);
  --pro-messages-box-shadow: 0px 4px 15px 0px rgb(99 87 188 / 30%);
}

/** expose custom colors */
.ion-color-black {
  --ion-color-base: var(--pro-color-black);
  --ion-color-base-rgb: var(--pro-color-black-rgb);
  --ion-color-contrast: var(--pro-color-black-contrast);
  --ion-color-contrast-rgb: var(--pro-color-black-contrast-rgb);
  --ion-color-shade: var(--pro-color-black-shade);
  --ion-color-tint: var(--pro-color-black-tint);
}

.ion-color-white {
  --ion-color-base: var(--pro-color-white);
  --ion-color-base-rgb: var(--pro-color-white-rgb);
  --ion-color-contrast: var(--pro-color-white-contrast);
  --ion-color-contrast-rgb: var(--pro-color-white-contrast-rgb);
  --ion-color-shade: var(--pro-color-white-shade);
  --ion-color-tint: var(--pro-color-white-tint);
}

/* dark-mode definitions */
body.dark {
  /** primary / swiss plum **/
  --ion-color-primary: #8979ff;
  --ion-color-primary-rgb: 137, 121, 255;
  --ion-color-primary-contrast: #000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #796ae0;
  --ion-color-primary-tint: #9586ff;

  /** secondary / green chill **/
  --ion-color-secondary: #33c3c3;
  --ion-color-secondary-rgb: 51, 195, 195;
  --ion-color-secondary-contrast: #000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #2dacac;
  --ion-color-secondary-tint: #47c9c9;

  /** tertiary / peach (no current change to light-mode) **/
  --ion-color-tertiary: #ffb87c;
  --ion-color-tertiary-rgb: 255, 184, 124;
  --ion-color-tertiary-contrast: #000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #e0a26d;
  --ion-color-tertiary-tint: #ffbf89;

  /** success /  loona moona **/
  --ion-color-success: #2b9393;
  --ion-color-success-rgb: 43, 147, 147;
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #268181;
  --ion-color-success-tint: #409e9e;

  /** warning / mat ruby (no current change to light-mode)  **/
  --ion-color-warning: #dd3447;
  --ion-color-warning-rgb: 221, 52, 71;
  --ion-color-warning-contrast: #fff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #c22e3e;
  --ion-color-warning-tint: #e04859;

  /** danger / not defined in figma yet **/
  --ion-color-danger: #ff919d;
  --ion-color-danger-rgb: 255, 145, 157;
  --ion-color-danger-contrast: #000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #e0808a;
  --ion-color-danger-tint: #ff9ca7;

  /** dark / mine shaft (no current change to light-mode) **/
  --ion-color-dark: #383838;
  --ion-color-dark-rgb: 56, 56, 56;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #313131;
  --ion-color-dark-tint: #4c4c4c;

  /** medium / magnesium **/
  --ion-color-medium: #717171;
  --ion-color-medium-rgb: 113, 113, 113;
  --ion-color-medium-contrast: #fff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #636363;
  --ion-color-medium-tint: #7f7f7f;

  /** light / mercury **/
  --ion-color-light: #353535;
  --ion-color-light-rgb: 53, 53, 53;
  --ion-color-light-contrast: #fff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #2f2f2f;
  --ion-color-light-tint: #494949;

  /** ionic colors **/
  --ion-background-color: var(--pro-color-zircon);
  --ion-background-color-rgb: var(--pro-color-zircon-rgb);
  --ion-text-color: var(--pro-color-black);
  --ion-text-color-rgb: var(--pro-color-black-rgb);
  --ion-item-background: var(--pro-color-white);
  --ion-item-color: var(--ion-text-color);
  --ion-border-color: #e5e5e5;
  --ion-box-shadow-color: rgb(0 0 0 / 10%);
  --ion-backdrop-color: var(--pro-color-black);

  /** ionic stepped colors **/
  --ion-color-step-50: #182f56;
  --ion-color-step-100: #243a5f;
  --ion-color-step-150: #304568;
  --ion-color-step-200: #3d5071;
  --ion-color-step-250: #495b7a;
  --ion-color-step-300: #556682;
  --ion-color-step-350: #61718b;
  --ion-color-step-400: #6d7c94;
  --ion-color-step-450: #79879d;
  --ion-color-step-500: #8692a6;
  --ion-color-step-550: #929caf;
  --ion-color-step-600: #9ea7b8;
  --ion-color-step-650: #aab2c1;
  --ion-color-step-700: #b6bdca;
  --ion-color-step-750: #c2c8d3;
  --ion-color-step-800: #ced3db;
  --ion-color-step-850: #dbdee4;
  --ion-color-step-900: #e7e9ed;
  --ion-color-step-950: #f3f4f6;

  /** Custom Colors
      black **/
  --pro-color-black: #fff;
  --pro-color-black-rgb: 255, 255, 255;
  --pro-color-black-contrast: #000;
  --pro-color-black-contrast-rgb: 0, 0, 0;
  --pro-color-black-shade: #e0e0e0;
  --pro-color-black-tint: #fff;

  /** white **/
  --pro-color-white: #000;
  --pro-color-white-rgb: 0, 0, 0;
  --pro-color-white-contrast: #fff;
  --pro-color-white-contrast-rgb: 255, 255, 255;
  --pro-color-white-shade: #000;
  --pro-color-white-tint: #1a1a1a;

  /** cranberry **/
  --pro-color-cranberry: #d74c82;
  --pro-color-cranberry-rgb: 215 76 130;
  --pro-color-cranberry-contrast: #fff;
  --pro-color-cranberry-contrast-rgb: 255, 255, 255;
  --pro-color-cranberry-shade: #bd4372;
  --pro-color-cranberry-tint: #db5e8f;

  /** blue-velvet **/
  --pro-color-blue-velvet: #7da4dd;
  --pro-color-blue-velvet-rgb: 125, 164, 221;
  --pro-color-blue-velvet-contrast: #000;
  --pro-color-blue-velvet-contrast-rgb: 0, 0, 0;
  --pro-color-blue-velvet-shade: #6e90c2;
  --pro-color-blue-velvet-tint: #8aade0;

  /** egg yolk sunrise (no current change to light-mode) **/
  --pro-color-egg-yolk-sunrise: #ffd60a;
  --pro-color-egg-yolk-sunrise-rgb: 255, 214, 10;
  --pro-color-egg-yolk-sunrise-contrast: #000;
  --pro-color-egg-yolk-sunrise-contrast-rgb: 0, 0, 0;
  --pro-color-egg-yolk-sunrise-shade: #e0bc09;
  --pro-color-egg-yolk-sunrise-tint: #ffda23;

  /** zircon **/
  --pro-color-zircon-night: #f4f8ff;
  --pro-color-zircon-rgb-night: 244, 248, 255;
  --pro-color-zircon-contrast-night: #000;
  --pro-color-zircon-contrast-rgb-night: 0, 0, 0;
  --pro-color-zircon-shade-night: #d7dae0;
  --pro-color-zircon-tint-night: #f5f9ff;

  /** zircon night **/
  --pro-color-zircon: #0c244d;
  --pro-color-zircon-rgb: 12, 36, 77;
  --pro-color-zircon-contrast: #fff;
  --pro-color-zircon-contrast-rgb: 255, 255, 255;
  --pro-color-zircon-shade: #0b2044;
  --pro-color-zircon-tint: #243a5f;

  /** hawkes blue **/
  --pro-color-hawkes-blue: #364867;
  --pro-color-hawkes-blue-rgb: 54, 72, 103;
  --pro-color-hawkes-blue-contrast: #fff;
  --pro-color-hawkes-blue-contrast-rgb: 255, 255, 255;
  --pro-color-hawkes-blue-shade: #303f5b;
  --pro-color-hawkes-blue-tint: #4a5a76;

  /** custom app vars **/
  --pro-default-border: solid 1px var(--ion-border-color);
  --pro-blurry-footer-background: rgb(12 36 77 / 40%);

  /** gradients **/
  --pro-gradient-swiss-plum: linear-gradient(180deg, #8979ff 0%, #948ae2 72.5%);
  --pro-gradient-swiss-plum-horizontal: linear-gradient(90deg, #8979ff 13.91%, #948ae2 92.35%);
  --pro-gradient-green-chill-horizontal: linear-gradient(93deg, #33c3c3 0%, #43f1f1 100%);
  --pro-gradient-green-chill: linear-gradient(180deg, #33c3c3 0%, #43f1f1 100%);
  --pro-gradient-green-chill-2: linear-gradient(270deg, #33b2c3 0%, #43f1f1 100%);
  --pro-gradient-peach: linear-gradient(269deg, #ffd4af 0%, #ffb87c 100%);
  --pro-gradient-message: linear-gradient(
    338deg,
    rgba(86 74 177 / 85%) 8.81%,
    rgba(159 147 254 / 85%) 85.3%
  );
  --pro-gradient-message-card-small-top-unread: linear-gradient(
    178deg,
    #a79efa -11.07%,
    rgba(167 158 250 / 51%) 48.47%,
    rgba(168 157 250 / 0%) 73.6%
  );
  --pro-gradient-message-card-small-bottom-unread: linear-gradient(
    0deg,
    rgba(255 255 255 / 40%) 4.55%,
    rgba(255 255 255 / 30%) 62.46%,
    rgba(255 255 255 / 0%) 100.6%
  );
  --pro-gradient-message-card-small-bottom-read: linear-gradient(
    0deg,
    rgba(0 0 0 / 60%) 4.05%,
    rgba(0 0 0 / 0%) 71.35%
  );
  --pro-gradient-message-card-big-unread: linear-gradient(45deg, #564ab1 0%, #9f93fe 92.5%);
  --pro-gradient-message-card-big-overlay-unread: linear-gradient(
    94deg,
    rgba(86 74 177 / 85%) 4.03%,
    rgba(112 100 205 / 54%) 61.52%,
    rgba(159 147 254 / 0%) 88.32%
  );

  /** box-shadows (no current change to light-mode) **/
  --pro-nav-bar-shadow: 0 4px 8px 0 rgb(0 0 0 / 25%);
  --pro-default-shadow: 0 2px 15px 0 rgb(0 0 0 / 10%);
  --pro-measurement-cta-shadow: 0 8px 8px 0 rgb(86 74 177 / 40%);
  --pro-messages-box-shadow: 0px 4px 15px 0px rgb(99 87 188 / 30%);
}
