ion-toolbar {
  --background: var(--ion-item-background);

  &.transparent {
    --background: transparent;
  }
}

ion-header {
  box-shadow: var(--pro-nav-bar-shadow);

  &.no-shadow {
    box-shadow: none;
  }

  ion-toolbar {
    --border-width: 0 !important;
    --padding-top: 0;
    --padding-end: 16px;
    --padding-bottom: 0;
    --padding-start: 16px;

    &:first-of-type {
      padding-top: 0;
    }
  }

  ion-back-button {
    --icon-margin-end: 0;
    --icon-margin-start: 0;

    font-size: 13px;
    color: var(--pro-color-black);
    width: 44px;
    height: 44px;
  }

  ion-button.header-save {
    font-size: 0.94rem;
    line-height: 1.15rem;
    font-weight: normal;
    margin: 0;
    color: var(--ion-color-primary);
    height: auto;
    text-transform: none;
    letter-spacing: normal;

    --color: var(--ion-color-primary);

    &::part(native) {
      padding: 0;
    }
  }
}

ion-title {
  font-size: 0.94rem;
  line-height: 1.15rem;
  font-weight: 800;
  padding: 0 16px;
}

/* fix notch-issues for web-preview */
.plt-desktop {
  ion-toolbar:first-of-type {
    padding-top: 20px;
  }

  --ion-safe-area-bottom: 22px;
}

/* stylelint-disable no-descending-specificity */

/** NEW DESIGN */
.pro-new-design {
  ion-header {
    box-shadow: none;
    padding-top: 8px;
    border-bottom: solid 2px var(--pro-color-white);

    &.header-translucent .header-background {
      backdrop-filter: blur(7.5px) !important;
    }

    &.no-border {
      border-bottom: none;
    }
  }

  ion-header ion-back-button {
    --icon-margin-end: 0;
    --icon-margin-start: 0;

    font-size: 18px;
    color: var(--ion-text-color);
    width: 44px;
    height: 44px;

    &::part(icon) {
      margin-bottom: 2px;
    }
  }

  ion-toolbar {
    --background: transparent;
  }

  ion-title {
    /* 17px / 25.5px */
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.2px;
    font-weight: 500;
    padding: 0 4px;
  }

  ion-progress-bar {
    position: absolute;
  }
}
