ion-searchbar.pro-searchbar {
  --border-radius: 25px;
  --background: var(--pro-color-white);
  --padding-start: 0;
  --padding-end: 0;

  height: 50px;
  min-height: auto;
  border-radius: 25px;
  background-color: var(--pro-color-white);
  padding-left: 12px;
  padding-right: 12px;

  .searchbar-input-container,
  .searchbar-input-container.sc-ion-searchbar-ios {
    height: 50px !important;
  }
}

/* NEW DESIGN */
.pro-new-design ion-searchbar {
  --background: var(--ion-color-light);
  --border-radius: 16px;
  --placeholder-color: var(--ion-color-dark);
  --placeholder-opacity: 1;

  padding: 0;
  min-height: auto;

  .searchbar-input {
    padding: 16px 0;
    text-indent: 20px;
  }

  .searchbar-search-icon {
    padding-left: 15px;
  }
}
