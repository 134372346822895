$primary-color: #74c4c3;
$primary-light-color: #e9f7f7;
$primary-medium-color: #339294;
$primary-dark-color: #419798;
$secondary-color: #665bbb;
$secondary-light-color: #deeaff;
$secondary-medium-color: #6859c0;
$secondary-dark-color: #5348a8;
$grey: #d8d8d8;
$grey-light: #d0dbef;
$grey-dark: #a8b3c9;
$background-color: #f4f9ff;

$pro-primary: (
  50: #dddbef,
  100: #c7c3e5,
  200: #aba5d8,
  300: #8e86cb,
  400: #7268be,
  500: #564ab1,
  600: #483e94,
  700: #393176,
  800: #393176,
  900: #393176,
  A100: #dddbef,
  A200: #c7c3e5,
  A400: #aba5d8,
  A700: #8e86cb,
  contrast: (
    50: #0000,
    100: #0000,
    200: #0000,
    300: #ffff,
    400: #ffff,
    500: #ffff,
    600: #ffff,
    700: #ffff,
    800: #ffff,
    900: #ffff,
    A100: #0000,
    A200: #0000,
    A400: #0000,
    A700: #ffff,
  ),
);

$pro-secondary: (
  50: #e4f0f0,
  100: #bddada,
  200: #91c2c2,
  300: #64aaaa,
  400: #439797,
  500: #228585,
  600: #1e7d7d,
  700: #197272,
  800: #146868,
  900: #0c5555,
  A100: #8bffff,
  A200: #58ffff,
  A400: #25ffff,
  A700: #0bffff,
  contrast: (
    50: #0000,
    100: #0000,
    200: #0000,
    300: #ffff,
    400: #ffff,
    500: #ffff,
    600: #ffff,
    700: #ffff,
    800: #ffff,
    900: #ffff,
    A100: #0000,
    A200: #0000,
    A400: #0000,
    A700: #ffff,
  ),
);

$pro-red: (
  50: #f8d6da,
  100: #f4bbc2,
  200: #ee99a3,
  300: #e87884,
  400: #e35666,
  500: #dd3447,
  600: #b82b3b,
  700: #93232f,
  800: #93232f,
  900: #93232f,
  A100: #f8d6da,
  A200: #f4bbc2,
  A400: #ee99a3,
  A700: #e87884,
  contrast: (
    50: #0000,
    100: #0000,
    200: #0000,
    300: #ffff,
    400: #ffff,
    500: #ffff,
    600: #ffff,
    700: #ffff,
    800: #ffff,
    900: #ffff,
    A100: #0000,
    A200: #0000,
    A400: #0000,
    A700: #ffff,
  ),
);
