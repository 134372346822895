ion-grid {
  --ion-grid-padding: 0;

  ion-col {
    --ion-grid-column-padding: 0;
  }
}

form ion-grid,
ion-grid form {
  ion-row {
    margin-bottom: 16px;
  }
}
