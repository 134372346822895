ion-card {
  border-radius: 20px 0 0 20px;
  box-shadow: var(--pro-default-shadow);
  margin: 16px 0;

  ion-item {
    --padding-top: 10px;
    --padding-bottom: 10px;
    --background-activated: transparent;

    min-height: 105px;
    display: flex;

    &::part(native) {
      border-radius: 20px 0 0 20px;
    }
  }

  ion-label {
    white-space: normal;
  }

  strong {
    display: block;
  }
}
